const Allocations = () => import('@/views/warehouse/allocation/index')
const CreateAllocations = () => import('@/views/warehouse/allocation/create')
const EditAllocations = () => import('@/views/warehouse/allocation/edit')
// const Reallocations = () => import('@/views/reallocation')

export default [
  {
    path: '/warehouse/allocations/all',
    name: 'Lista de Endereços',
    component:  Allocations,
    meta: {
      breadcrumbParent: {
        path: '/warehouse/all',
        name: 'Armazéns'
      }
    }
  },
  {
    path: '/warehouse/allocations/new',
    name: 'Novo Endereço',
    component:  CreateAllocations,
    meta: {
      breadcrumbParent: {
        path: '/warehouse/allocations/all',
        name: 'Lista de Endereços',
        meta: {
          breadcrumbParent: {
            path: '/warehouse/all',
            name: 'Armazéns',
          }
        }
      }
    }
  },
  {
    path: '/warehouse/allocations/edit/:id',
    name: 'Editar Endereço',
    component:  EditAllocations,
    meta: {
      breadcrumbParent: {
        path: '/warehouse/allocations/all',
        name: 'Lista de Endereços',
        meta: {
          breadcrumbParent: {
            path: '/warehouse/all',
            name: 'Armazéns',
          }
        }
      }
    }
  },
]
