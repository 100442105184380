import io from 'socket.io-client';
import { HTTP } from '../service/http-commons';

export default {
  SORT_BY_VALUES_LOCAL_STORAGE_KEY: 'wms/reports/sortByValues',

  formatDate(value, options) {
    if (!value) {
      return value;
    }

    if (!options) {
      options = {
        includeSeconds: true
      };
    }

    let data = new Date(value),
    diaF, mesF, anoF, formattedDate, formattedTime;

    if (options.returnAccordingToCurrentDay) {
      if (this.isToday(data)) {
        options.timeOnly = true;
      } else {
        options.dateOnly = true;
      }
    }

    if (!options.timeOnly) {
      let dia  = data.getDate().toString();
      diaF = (dia.length == 1) ? '0'+dia : dia;

      let mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0'+mes : mes;

      anoF = data.getFullYear();
      formattedDate = diaF+"/"+mesF;

      if (!options.omitYearIfCurrent || new Date().getFullYear() !== anoF) {
        formattedDate += "/"+anoF;
      }
    }

    if (!options.dateOnly) {
      let hora = data.getHours().toString(),
      horaF = (hora.length == 1) ? '0'+hora : hora,

      minuto = data.getMinutes().toString(),
      minutoF = (minuto.length == 1) ? '0'+minuto : minuto;

      formattedTime = horaF+":"+minutoF;

      if (options.includeSeconds) {
        let segundos = data.getSeconds().toString(),
        segundosF = (segundos.length == 1) ? '0'+segundos : segundos;

        formattedTime += ":"+segundosF;
      }
    }

    if (options.dateOnly) {
      return formattedDate;
    } else if (options.timeOnly) {
      return formattedTime;
    } else {
      return formattedDate+" "+formattedTime;
    }
  },

  isToday(date) {
    let today = new Date();

    return today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear();
  },

  getSuggestionFromBalances(balances, quantity, previousSuggestions) {
    let suggestions = [];

    if (balances && balances.length) {
      let remainingQuantity = Number(quantity);

      for (let i = 0; i < balances.length; i++) {
        let balance = balances[i];
        let balanceQuantity = balance.entries - balance.exits;

        if (balanceQuantity > 0) {
          let automatic = true;
          let numberOfVolumes = 0;
          let quantityToBeSeparated = balanceQuantity;

          if (balanceQuantity > remainingQuantity) {
            quantityToBeSeparated = remainingQuantity;
          }

          if (previousSuggestions && previousSuggestions.length) {
            let foundCurrentSuggestions = previousSuggestions.filter(s => s.id === balance.id);

            if (foundCurrentSuggestions && foundCurrentSuggestions.length) {
              let foundCurrentSuggestion = foundCurrentSuggestions[0];
              automatic = foundCurrentSuggestion.automatic;
              numberOfVolumes = foundCurrentSuggestion.numberOfVolumes;

              if (!foundCurrentSuggestion.automatic) {
                quantityToBeSeparated = foundCurrentSuggestion.quantity;
              }
            }
          }

          remainingQuantity -= quantityToBeSeparated;
          let suggestion = balance;
          balance.quantity = quantityToBeSeparated;
          balance.formattedDate = balance.itemLot ? this.formatDate(balance.itemLot.createdAt) : balance.formattedDate;
          balance.automatic = automatic;
          balance.numberOfVolumes = numberOfVolumes;
          balance.currentQuantity = balanceQuantity;

          suggestions.push(suggestion);

          if (remainingQuantity <= 0) {
            break;
          }
        }
      }
    }

    return suggestions;
  },

  operationBadgeClassObject(item) {
    return {
      'badge-primary': item.fiscalOperation && (item.fiscalOperation.showType === 'ENT' || item.fiscalOperation.showType === 'SAI'),
      'badge-warning': item.fiscalOperation && item.fiscalOperation.showType === 'PRODUCAO',
      'badge-success': item.fiscalOperation && item.fiscalOperation.showType === 'TRANSFORMACAO',
      'orange-background': item.fiscalOperation && item.fiscalOperation.showType === 'TRANSFERENCIA',
      'badge-danger': item.fiscalOperation && item.fiscalOperation.showType === 'AJU',
      'badge-dark': item.fiscalOperation && item.fiscalOperation.showType === 'RAL',
    }
  },

  getItemReduzidoFromRef(ref) {
    if (ref && ref.length >= 6) {
      return ref.substring(ref.length - 6, ref.length);
    }

    return ref;
  },

  mountReduzidoFilter(filter, size) {
    if (!filter) {
      filter = '';
    }

    if (!size) {
      size = 6;
    }

    if (filter.length < size) {
      filter = filter.padStart(size, '0');
    } else if (filter.length > size) {
      filter = filter.substring(filter.length - size, filter.length);
    }

    return filter;
  },

  getLocationTypeLabel(type) {
    if (type) {
      switch (type) {
        case 'F': return "Item fixo";
        case 'D': return "Item dinâmico";
        case 'U': return 'Item indefinido';
      }
    }

    return null;
  },

  countDecimals(value) {
    if(!value || Math.floor(value) === value) return 0;

    let str = value.toString();

    if (str.includes('e-')) {
      let index = str.indexOf('e-') + 2;

      if (index <= str.length - 1) {
        return Number(str.substring( + 2)) || 0;
      }
    }

    let split = str.split(".");

    if(split.length > 1)
      return split[1].length;

    else
      return 0;
  },

  fixRounding(value, precision) {
    var power = Math.pow(10, precision || 0);
    return Math.round(value * power) / power;
  },

  geBiggestDecimal(value1, value2) {
    return Math.max(this.countDecimals(value1), this.countDecimals(value2));
  },

  getDivisor(biggestDecimal) {
    return Math.pow(10, biggestDecimal);
  },

  removeDecimalPart(values) {
    if (!values) {
      values = [];
    }

    if (values.length < 1) {
      values[0] = 0;
    }

    if (values.length < 2) {
      values[1] = 0;
    }

    if (!values[0]) {
      values[0] = 0;
    }

    if (!values[1]) {
      values[1] = 0;
    }

    let biggestDecimal = this.geBiggestDecimal(values[0], values[1]);

    if (biggestDecimal > 0) {
      for (let i = 0; i < values.length; i++) {
        let v = values[i];

        v = v.toString();

        let indexOfDot = v.indexOf('.');

        if (indexOfDot === -1) {
          indexOfDot = v.length;
        }

        v = v.replace(".", "");

        while (v.length < indexOfDot + biggestDecimal) {
          v = v + '0';
        }

        values[i] = Number(v);
      }
    }

    return {
      values: values,
      biggestDecimal: biggestDecimal,
      divisor: this.getDivisor(biggestDecimal)
    };
  },

  add(value1, value2) {
    let obj = this.removeDecimalPart([value1, value2]);
    return (obj.values[0] + obj.values[1]) / obj.divisor;
  },

  subtract(value1, value2) {
    let obj = this.removeDecimalPart([value1, value2]);
    return (obj.values[0] - obj.values[1]) / obj.divisor;
  },

  multiply(value1, value2) {
    let obj = this.removeDecimalPart([value1, value2]);
    return (obj.values[0] * obj.values[1]) / obj.divisor;
  },

  divide(value1, value2) {
    let obj = this.removeDecimalPart([value1, value2]);
    return (obj.values[0] / obj.values[1]) / obj.divisor;
  },

  getConfig() {
    let configStr = localStorage.getItem('config');
    let config = {};

    if (configStr && configStr.length) {
      try {
        config = JSON.parse(configStr);

        if (!('itemsOutConfig' in config)) {
          config.itemsOutConfig = this.getDefaultItemsOutConfig();
        }

        if (!('manualSelectionConfig' in config)) {
          config.manualSelectionConfig = this.getDefaultManualSelectionConfig();
        }

        if (!('printConfig' in config)) {
          config.printConfig = this.getDefaultPrintConfig();
        }

        if (!('separatedLots' in config.printConfig)) {
          config.printConfig.separatedLots = this.getDefaultPrintSeparatedLotsConfig();
        }

        if (!('itemColumnFields' in config.printConfig.separatedLots)) {
          config.printConfig.separatedLots.itemColumnFields = this.getDefaultPrintSeparatedLotsConfig().itemColumnFields;
        }

        if (!('orderBy' in config.printConfig.separatedLots)) {
          config.printConfig.separatedLots.orderBy = this.getDefaultPrintSeparatedLotsConfig().orderBy;
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      config = this.getDefaultConfig();
    }

    /*if (!config.printConfig.model || !config.printConfig.model.label) {
      config.printConfig.model = this.getItemLotPrintModels()[1];
    }*/

    return config;
  },

  getLocalStorageObj(key) {
    let objString = localStorage.getItem(key);

    if (objString && objString.length) {
      try {
        return JSON.parse(objString);
      } catch (e) {
        console.log(e);
      }
    }

    return {};
  },

  setLocalStorageObj(key, value) {
    if (key && (value || typeof value === "boolean")) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  },

  getLocalStorageString(key) {
    let str = localStorage.getItem(key);

    if (str && str.length) {
      return str;
    } else {
      return '';
    }
  },

  setLocalStorageString(key, value) {
    if (key) {
      localStorage.setItem(key, value ? value.toString() : '');
    }
  },

  getOrderByFieldOptions() {
    return [
      { value: 'departureDate', label: 'Data de Embarque' },
      { value: 'deliveryDate', label: 'Data Prevista de Entrega' },
      { value: 'createdAt', label: 'Data de Sincronização' },
      { value: 'number', label: 'Numero do pedido' },
      { value: 'issueDate', label: 'Data de emissão' }
    ];
  },

  getManualSelectionDefaultQuantityOptions() {
    return [
      { value: 'one_volume', label: 'Um volume (ou uma unidade, caso o item seja de lote automático)' },
      { value: 'remaining_quantity', label: 'Quantidade restante do item' }
    ];
  },

  getDefaultConfig() {
    return {
      printConfig: this.getDefaultPrintConfig(),
      manualSelectionConfig: this.getDefaultManualSelectionConfig(),
      itemsOutConfig: this.getDefaultItemsOutConfig()
    };
  },

  getDefaultManualSelectionConfig() {
    return {
      defaultQuantityBehavior: this.getManualSelectionDefaultQuantityOptions()[0].value
    }
  },

  getDefaultItemsOutConfig() {
    return {
      allowItemLotsOutOfSuggestions: true,
      orderByField: [this.getOrderByFieldOptions()[0]]
    };
  },

  getDefaultPrintConfig() {
    return {
      model: {},
      locationModel: {},
      width: 100,
      height: 75,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      pageWidth: 100,
      pageHeight: 75,
      fontSize: 21,
      centered: false,
      bold: false,
      barcodeHeightPercentage: 35,
      separatedLots: this.getDefaultPrintSeparatedLotsConfig()
    };
  },

  getDefaultPrintSeparatedLotsConfig() {
    return {
      itemColumnFields: [ 'ref', 'description' ],
      orderBy: [
        {
          condition: { field: 'itemLot.createdAt' },
          direction: 'ASC'
        },
        {
          condition: { field: 'itemLot.lotNumber' },
          direction: 'ASC'
        },
        {
          condition: { field: 'itemLot.item.description' },
          direction: 'ASC'
        }
      ]
    };
  },

  getPrintSeparatedLotsConfigOrderFieldOptions() {
    return [
      { field: 'itemLot.createdAt', label: 'Data de criação do lote' },
      { field: 'itemLot.due', label: 'Data de validade do lote' },
      { field: 'itemLot.lotNumber', label: 'Número do lote' },
      { field: 'itemLot.item.description', label: 'Descrição do item' },
      { field: 'itemLot.item.ref', label: 'Item reduzido' },
      { field: 'itemLot.item.sku', label: 'Item referência' },
      { field: 'location.name', label: 'Nome do endereço' }
    ];
  },

  getPrintSeparatedLotsConfigItemColumnFieldOptions() {
    return [
      { field: 'description', label: 'Descrição' },
      { field: 'gtin', label: 'Código de barras' },
      { field: 'ref', label: 'Reduzido' },
      { field: 'sku', label: 'Referência' },
      { field: 'manufacturerReference', label: 'Referência do fabricante' }
    ];
  },

  suggestionSortByItemLotDateFunction(a, b) {
    if (a.itemLot && b.itemLot) {
      if (a.itemLot.createdAt > b.itemLot.createdAt) {
          return 1;
      }

      if (b.itemLot.createdAt > a.itemLot.createdAt) {
          return -1;
      }
    }

    return a.sortIndex - b.sortIndex;
  },

  suggestionSortByItemLotLotNumberFunction(a, b) {
    if (a.itemLot && b.itemLot) {
      if (a.itemLot.lotNumber > b.itemLot.lotNumber) {
        return 1;
      }

      if (b.itemLot.lotNumber > a.itemLot.lotNumber) {
        return -1;
      }
    }

    return a.sortIndex - b.sortIndex;
  },

  suggestionSortByLocationNameFunction(a, b) {
    if (a.location && b.location) {
      let aLocationName = a.location.name;
      let bLocationName = b.location.name;

      if (aLocationName) {
        aLocationName = aLocationName.toString().toUpperCase();
      }

      if (bLocationName) {
        bLocationName = bLocationName.toString().toUpperCase();
      }

      if (aLocationName > bLocationName) {
          return 1;
      }

      if (bLocationName > aLocationName) {
          return -1;
      }
    }

    return a.sortIndex - b.sortIndex;
  },

  setSortIndex(array) {
    if (array) {
      array.forEach(function(item, index) {
        item.sortIndex = index;
      });
    }

    return array;
  },

  sortByItemLotDate(array) {
    if (array) {
      array = this.setSortIndex(array);
      array.sort(this.suggestionSortByItemLotDateFunction);
    }

    return array;
  },

  sortByItemLotLotNumber(array) {
    if (array) {
      array = this.setSortIndex(array);
      array.sort(this.suggestionSortByItemLotLotNumberFunction);
    }

    return array;
  },

  sortByLocationName(array) {
    if (array) {
      array = this.setSortIndex(array);
      array.sort(this.suggestionSortByLocationNameFunction);
    }

    return array;
  },

  sortByLocationGroup(array, locationGroupers) {
    if (array && locationGroupers && locationGroupers.length) {
      array = this.setSortIndex(array);
      array = array.sort((a, b) => {
        if (
          a.location && b.location &&
          a.location.locationGroups && b.location.locationGroups &&
          a.location.locationGroups.length && b.location.locationGroups.length
        ) {
          for (let grouper of locationGroupers) {
            let findGroupFunction = g => g.locationGrouper && g.locationGrouper.id === grouper.id;

            let aGroup = a.location.locationGroups.find(findGroupFunction);
            let bGroup = b.location.locationGroups.find(findGroupFunction);

            if (aGroup && bGroup) {
              if (aGroup.name > bGroup.name) {
                return 1;
              }

              if (bGroup.name > aGroup.name) {
                return -1;
              }
            }
          }
        }

        return a.sortIndex - b.sortIndex;
      });
    }

    return array;
  },

  sortByItemLotLotNumberAndLocationName(array) {
    return this.sortByLocationName(this.sortByItemLotLotNumber(array));
  },

  sortByItemLotDateAndItemLotLotNumberAndLocationName(array) {
    return this.sortByLocationName(this.sortByItemLotLotNumber(this.sortByItemLotDate(array)));
  },

  async getItemLotPrintModels(httpClient, options) {
    let models = [
      { label: 'Padrão', value: null }
    ];

    let conditions = [
      {
        field: 'active',
        value: true
      }
    ];

    if (!options) {
      throw 'Invalid option';
    }

    if (options.lotOnly) {
      conditions.push({
        field: 'type',
        value: 'itmlot'
      });
    } else if (options.locationOnly) {
      conditions.push({
        field: 'type',
        value: 'locati'
      });
    } else if (!options.both) {
      throw 'Invalid option';
    }

    let data = await httpClient
    .post(`${process.env.VUE_APP_API_URL}templates/select-and-where?page=0&size=100`, {
      select: ['id', 'name', 'type'],
      where: {
        conditions: conditions,
        orderBies: [
          {
            direction: 'DESC',
            condition: { field: 'createdAt' }
          }
        ]
      }
    });

    let templates = data.data.data.content;
    templates.forEach(t => {
      t.value = { id: t.id };
      t.label = t.name + ' (Personalizado)';
    });
    models.push(...templates);

    if (options.lotOnly || options.both) {
      let authUser = this.getLocalStorageObj('auth/user');

      if (authUser && authUser.companyGroup && authUser.companyGroup.id) {
        if (authUser.companyGroup.id === '1300d1a5-bd3e-441c-99e9-d7ddbb4fef4d' //JMS
          || authUser.companyGroup.id === '5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac') {
          models.push(...[
            { label: 'JMS Normal', value: 'jms-normal' },
            { label: 'JMS Feltro', value: 'jms-feltro' },
            { label: 'JMS Festcolor', value: 'jms-festcolor' },
            { label: 'JMS Revenda', value: 'jms-revenda' },
            { label: 'JMS Piffer 24 Painéis', value: 'jms-piffer-24-paineis' },
            { label: 'JMS Piffer 49 Painéis', value: 'jms-piffer-49-paineis' },
            { label: 'JM Normal', value: 'jm-normal' },
            { label: 'JM Feltro', value: 'jm-feltro' },
            { label: 'JM Festcolor', value: 'jm-festcolor' },
            { label: 'JM Revenda', value: 'jm-revenda' },

          ]);
        }

        if (authUser.companyGroup.id === 'a4d6dc38-62c9-48fc-942f-413750fe7561' //Spinner
          || authUser.companyGroup.id === '5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac') {
          models.push(...[
            { label: 'Spinner Normal', value: 'spinner-normal' },
          ]);
        }
      }
    }

    return models;
  },

  getBarcodeModelsFromItemLots(itemLots, model) {
    let barcodeModels = [];

    itemLots.forEach(itemLot => {
      if (itemLot.printBarcode
        && Number(itemLot.printQuantity) > 0
        && itemLot.barcode
        && itemLot.barcode.length) {
        if (model.value) {
          barcodeModels.push({
            barcode: itemLot.barcode,
            itemLot: itemLot,
            quantity: itemLot.printQuantity,
            meta: []
          });
        } else {
          let itemDescription = null;
          let itemBarcode = '';
          let lotNumber = null;
          let meta = [];
          let sku = '';

          if (itemLot.item) {
            itemDescription = this.getItemReduzidoFromRef(itemLot.item.ref) + ' - ' + itemLot.item.description;
            itemBarcode = itemLot.item.gtin;
            sku = itemLot.item.sku;
          }

          if (itemDescription) {
            meta = [itemDescription];
          }

          meta.push(...[
            itemLot.lotNumber,
            itemLot.createdAt ? this.formatDate(itemLot.createdAt) : ''
          ]);

          barcodeModels.push({
            barcode: itemLot.barcode,
            quantity: itemLot.printQuantity,
            itemDescription: itemDescription,
            itemBarcode: itemBarcode,
            lotNumber: lotNumber,
            quantityPerVolume: itemLot.quantityPerVolume,
            meta: meta,
            sku: sku
          });
        }
      }
    });

    return barcodeModels;
  },

  sortLocationGroupers(locationGroupers) {
    if (locationGroupers && locationGroupers.length) {
      let newLocationGroupers = locationGroupers.filter(g => !g.parentLocationGrouper);

      if (newLocationGroupers.length) {
        let children;
        let verifiedIds = [];

        do {
          let idsToVerify = newLocationGroupers.map(g => g.id).filter(id => !verifiedIds.includes(id));
          children = locationGroupers.filter(g => {
            return g.parentLocationGrouper && idsToVerify.includes(g.parentLocationGrouper.id)
          });
          verifiedIds.push(...idsToVerify);
          newLocationGroupers.push(...children);
        } while (children.length);

        locationGroupers = newLocationGroupers;
      }
    }

    return locationGroupers;
  },

  sortLocationGroups(locationGroups, locationGroupers) {
    if (locationGroups && locationGroups.length) {
      if (!locationGroupers) {
        locationGroupers = this.sortLocationGroupers(
          locationGroups.map(group => group.locationGrouper).filter(grouper => grouper)
        );
      }

      let newLocationGroups = [];

      locationGroupers.forEach(grouper => {
        newLocationGroups.push(...locationGroups.filter(g => g.locationGrouper && g.locationGrouper.id === grouper.id));
      });

      return newLocationGroups;
    }

    return locationGroups;
  },

  getLocationGroupsWithJustNames(locationGroups) {
    if (locationGroups) {
      let lg = {};

      for (let grouperId in locationGroups) {
        lg[grouperId] = { name:  locationGroups[grouperId].name };
      }

      return lg;
    }

    return locationGroups;
  },

  getHoursAndMinutesFromTime(time, includeSeconds) {
    let hours = Math.floor(time / 3600000);
    let rest = time % 3600000;
    let minutes = Math.floor(rest / 60000);
    let hoursAndMinutes = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');

    if (includeSeconds) {
      rest = rest % 60000;
      let seconds = Math.floor(rest / 1000);

      hoursAndMinutes += ":" + seconds.toString().padStart(2, '0');
    }

    return hoursAndMinutes;
  },

  setOrderShowType(order) {
    if (order.transferId) {
      return 'TRANSFERENCIA';
    } else if (order.transformationMSId) {
      return 'TRANSFORMACAO';
    } else if (order.productionMSId) {
      return 'PRODUCAO';
    } else if (order.stockAdjustmentId) {
      return 'AJU';
    } else if (order.fiscalOperation) {
      return order.fiscalOperation.type;
    } else {
      return null;
    }
  },

  setAttendanceFields(orderAttendance, endDate, includeSeconds) {
    orderAttendance.formattedStartDate = this.formatDate(orderAttendance.startDate);
    orderAttendance.formattedEndDate = this.formatDate(orderAttendance.endDate);

    if (!endDate) {
      endDate = orderAttendance.endDate;
    }

    orderAttendance = this.setOrderAttendanceHoursAndMinutes(orderAttendance, endDate, includeSeconds);

    if (orderAttendance.order && orderAttendance.order.fiscalOperation) {
      orderAttendance.showType = orderAttendance.order.fiscalOperation.type;
    } else if (orderAttendance.stockAdjustment) {
      orderAttendance.showType = 'AJU';
    }

    return orderAttendance;
  },

  setOrderAttendanceHoursAndMinutes(orderAttendance, endDate, includeSeconds) {
    if (orderAttendance.startDate) {
      if (endDate) {
        endDate = new Date(endDate);
      } else {
        endDate = new Date();;
      }

      orderAttendance.hoursAndMinutes = this.getHoursAndMinutesFromTime(
        endDate.getTime() - new Date(orderAttendance.startDate).getTime(), includeSeconds
      );
    }

    return orderAttendance;
  },

  getSocketIOClient() {
    /*let socket = io(process.env.VUE_APP_SOCKETIO_URL);

    if (socket) {
      socket.on('connect', () => {
        socket.emit('authentication', {
            userEmail: "wms@teorema.inf.br",
            date: new Date(),
            channel: "wms.teorema.inf.br",
            systemName: "wms",
        });
      });
    }

    return socket;*/
  },

  paginateArray(items, current_page, per_page_items) {
    let page = current_page || 1,
    per_page = per_page_items || 10,
    offset = (page - 1) * per_page,

    paginatedItems = items.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items.length / per_page);

    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: (total_pages > page) ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems
    };
  },

  paginateArrayInPages(items, pageSize) {
    let pages = [];

    let currentPage = 1;
    while (true) {
      let pageObj = this.paginateArray(items, currentPage, pageSize);

      if (pageObj.data && pageObj.data.length) {
        pages[currentPage - 1] = pageObj.data;

        if (!pageObj.next_page) {
          break;
        }
      } else {
        break;
      }

      currentPage++;
    }

    return pages;
  },

  fetchAuthUser(userId, token) {
    return new Promise((resolve, reject) => {
      //this.setLocalStorageString('auth/token', user.token);
      //this.setLocalStorageObj('auth/user', user);

      HTTP.get(`${process.env.VUE_APP_API_URL}users/${userId}`).then(({ data }) => {
        if (!data.data) {
          console.log('Verifique o banco de dados');
          reject('Usuário ' + userId + ' não encontrado');
          return;
        }

        data.data.token = token;
        this.setLocalStorageObj('auth/user', data.data);
        resolve(data.data);
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  },

  entitiesAreEqual(entity1, entity2, key = 'id') {
    return entity1 && entity2 && entity1[key] === entity2[key];
  },

  copyText(text) {
    return navigator.clipboard.writeText(text);
  },

  downloadString(text, fileType, fileName) {
    var blob = new Blob([text], { type: fileType });

    var a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function() { URL.revokeObjectURL(a.href); }, 1);
  },

  saveScrollPosition(key) {
    let scrollPos;
    if (typeof window.pageYOffset != 'undefined') {
      scrollPos = window.pageYOffset;
    }
    else if (typeof document.compatMode != 'undefined' && document.compatMode != 'BackCompat') {
      scrollPos = document.documentElement.scrollTop;
    }
    else if (typeof document.body != 'undefined') {
      scrollPos = document.body.scrollTop;
    }

    this.setLocalStorageString(key, scrollPos);
  },

  restoreScrollPosition(key) {
    let scrollPosition = this.getLocalStorageString(key);

    if (scrollPosition) {
      document.documentElement.scrollTop = parseInt(scrollPosition);
      document.body.scrollTop = parseInt(scrollPosition);
    }
  },

  setModelAndTemplateIdFromSelectedBarcodeModel(config, model) {
    if (model && model.value && model.value.id) {
      config.printConfig.templateId = model.value.id;
      config.printConfig.model = null;
    } else {
      config.printConfig.model = model ? model.value : null;
      config.printConfig.templateId = null;
    }

    return config;
  },

  getAllocationRuleTypes() {
    return [
      {
        value: 'LOCATION_GROUPER',
        label: 'Agrupador',
        hint: 'Prioriza endereços em que os agrupadores estejam de acordo com a condição definida'
      },
      {
        value: 'BRAND',
        label: 'Marca',
        hint: 'Prioriza endereços com a mesma marca do item'
      }
    ];
  },

  getAllocationSortFieldOptions() {
    return [
      {
        value: 'LOCATION_GROUP',
        label: 'Grupo',
        hint: 'Ordena os resultados pelos grupos (rua, bloco, andar, etc.)'
      },
      {
        value: 'ITEM_LOT_DATE',
        label: 'Data do lote',
        hint: 'Ordena os resultados pela data de fabricação do dote',
        orderType: 'S'
      },
      {
        value: 'LOCATION_NAME',
        label: 'Nome do endereço',
        hint: 'Ordena os resultados pelo nome do endereço'
      },
      {
        value: 'ITEM_LOT_NUMBER',
        label: 'Número do lote',
        hint: 'Ordena os resultados pelo número do lote',
        orderType: 'S'
      }
    ];
  },

  getAuthUser() {
    return this.getLocalStorageObj("auth/user");
  },

  getParameterFromAuthUser(authUser) {
    if (
      authUser &&
      authUser.companyGroup &&
      authUser.companyGroup.parameter
    ) {
      return authUser.companyGroup.parameter;
    } else {
      return {};
    }
  },

  getCompanyGroup() {
    let authUser = this.getLocalStorageObj("auth/user");

    if (authUser) {
      return authUser.companyGroup;
    }
  },

  useCamera(parameter, config) {
    return (parameter && parameter.barcodeReadMethod === 'C');
  },

  autoOpenCamera(parameter, config) {
    return (parameter && parameter.autoOpenCamera);
  },

  sortByAllocationSortFields(array, allocationSortFields, locationGroupers) {
    if (allocationSortFields && allocationSortFields.length) {
      let reversedSortFields = allocationSortFields.slice().reverse();

      reversedSortFields.forEach(sortField => {
        if (sortField.field) {
          switch (sortField.field) {
            case 'LOCATION_GROUP': {
              return this.sortByLocationGroup(array, locationGroupers);
            }
            case 'ITEM_LOT_DATE': {
              return this.sortByItemLotDate(array);
            }
            case 'LOCATION_NAME': {
              return this.sortByLocationName(array);
            }
            case 'ITEM_LOT_NUMBER': {
              return this.sortByItemLotLotNumber(array);
            }
          }
        }
      });
    } else {
      return this.sortByItemLotDateAndItemLotLotNumberAndLocationName(array);
    }

    return array;
  },

  getOperations() {
    return [
      { label: 'Todas', value: null },
      { label: 'Entrada', value: 'ENT' },
      { label: 'Saída', value: 'SAI' },
      { label: 'Transferência', value: 'TRANSFERENCIA' },
      { label: 'Transformação', value: 'TRANSFORMACAO' },
      { label: 'Produção', value: 'PRODUCAO' },
      { label: 'Ajuste', value: 'AJU' },
    ];
  },

  setProfileAvailableOperations(_this) {
    if (
      _this.authUser &&
      _this.authUser.profile &&
      _this.authUser.profile.restrictions &&
      _this.authUser.profile.restrictions.order &&
      _this.authUser.profile.restrictions.order.fiscal_operations &&
      _this.authUser.profile.restrictions.order.fiscal_operations.length
    ) {
      _this.operations = _this.operations.filter(op => !op.value || _this.authUser.profile.restrictions.order.fiscal_operations.includes(op.value));

      if (_this.operations.length <= 2) {
        let allIndex = _this.operations.findIndex(o => !o.value);

        if (allIndex > -1) {
          _this.operations.splice(allIndex, 1);
        }

        if (_this.operations.length === 1) {
          _this.selectedOperation = _this.operations[0];
        }
      }
    }
  }

}
