import firebase from 'firebase';
import "firebase/database";

var firebaseConfig = {
    /*apiKey: "AIzaSyAH1XD5VyEZ11XIz1-KsPnM_U8pSHWr25w",
    authDomain: "teorema-wms.firebaseapp.com",
    projectId: "teorema-wms",
    storageBucket: "teorema-wms.appspot.com",
    messagingSenderId: "445425402144",
    appId: "1:445425402144:web:59c135e99a2efba60a1745"*/
    apiKey: "AIzaSyCQyqze1Nrlpb0Tf5mq3VwR3_9JecXXPjI",
    authDomain: "fir-auth-144e4.firebaseapp.com",
    projectId: "fir-auth-144e4",
    storageBucket: "fir-auth-144e4.appspot.com",
    messagingSenderId: "445364286312",
    appId: "1:445364286312:web:f7386846b4b417ef9c1cdd"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase.database();


