import { HTTP } from '../http-commons'
import shared from '@/shared/shared';

export default class Orders {

  async getAllBy (url) {
     return await HTTP.get(url)
  }

  async startPrepare ({ orderUUID, time }) {
    const { start } = shared.getLocalStorageObj('endpoints/task');
    const { uuid:userUuid, name:userName } = shared.getLocalStorageObj('auth/user');
    const url = start.href.replace('{orderUUID}', orderUUID)

    const { data:task } = await  HTTP.post(url, { time, userUuid, userName })

    shared.setLocalStorageObj('task/task', task);

    return task
  }

  async stopPrepare ({ stop }, { time }) {
    const { uuid:userUuid, name:userName } = shared.getLocalStorageObj('auth/user');
    const { data:task } = await  HTTP.put(stop.href, { time, userUuid, userName })

    shared.setLocalStorageObj('task/task', task);

    return task
  }

  async decrementOrderItem ({ uuid, amount, name }) {
    const { decrementQuantityItem: { href:decrement } } = shared.getLocalStorageObj('endpoints/order');
    const { uuid:taskUUID } = shared.getLocalStorageObj('task/currentTask');

    const { data } = await HTTP.patch(decrement.replace('{UUID}', uuid), { uuid, amount , allocationName: name, taskUUID})

    return data
  }

  async incrementOrderItem({ uuid, amount, name }) {
    const { incrementQuantityItem:  { href:increment} } = shared.getLocalStorageObj('endpoints/order');
    const { uuid:taskUUID } = shared.getLocalStorageObj('task/currentTask');

    const { data } = await HTTP.patch(increment.replace('{UUID}', uuid), { uuid, amount , allocationName: name, taskUUID })

    return data
  }

  async completeOrder ({ complete }, { time }) {
    const { uuid:userUuid, name:userName } = shared.getLocalStorageObj('auth/user');

    const { data:task } = await HTTP.put(complete.href, { time, userUuid, userName })

    shared.setLocalStorageObj('task/task', {});

    return task
  }
}
