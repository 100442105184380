const Reallocations = () =>import('@/views/orders/reallocation/reallocation-all')
const OrderStart = () => import('@/views/orders/prepare/prepare-start')
const CreateReallocation = () =>import('@/views/orders/reallocation/create')
const EditReallocation = () =>import('@/views/orders/reallocation/edit')

export default [
  {
    path: '/orders/item-reallocation/all',
    name: 'Realocação de Itens',
    component:  Reallocations,
  },
  {
    path: '/orders/item-reallocation/new',
    name: 'Nova Realocação',
    component:  CreateReallocation,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-reallocation/all',
        name: 'Realocação de Itens'
      }
    }
  },
  {
    path: '/orders/item-reallocation/edit/:id',
    name: 'Editar Realocação',
    component:  EditReallocation,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-reallocation/all',
        name: 'Realocação de Itens'
      }
    }
  },
  {
    path: '/orders/item-reallocation/start/:id',
    name: 'Executando Realocação',
    component: OrderStart,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-reallocation/all',
        name: 'Realocação de Itens'
      }
    }
  }
]
