const Adjustments = () =>import('@/views/orders/adjustment/adjustment-all')
const CreateAdjustment = () =>import('@/views/orders/adjustment/create')
const EditAdjustment = () =>import('@/views/orders/adjustment/edit')
const LocationAndLotAdjustment = () =>import('@/views/orders/adjustment/location-and-lot-adjustment')
const AutoFillRevision = () =>import('@/views/orders/adjustment/auto-fill-revision')

export default [
  {
    path: '/orders/item-adjustment/all',
    name: 'Ajuste de Itens',
    component:  Adjustments,
  },
  {
    path: '/orders/item-adjustment/new',
    name: 'Novo Ajuste - Por Item',
    component:  CreateAdjustment,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-adjustment/all',
        name: 'Ajuste de Itens'
      }
    }
  },
  {
    path: '/orders/item-adjustment/edit/:id',
    name: 'Abrir/Editar Ajuste - Por Item',
    component:  EditAdjustment,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-adjustment/all',
        name: 'Ajuste de Itens'
      }
    }
  },
  {
    path: '/orders/location-and-lot-item-adjustment/new',
    name: 'Novo Ajuste - Inventário',
    component:  LocationAndLotAdjustment,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-adjustment/all',
        name: 'Ajuste de Itens'
      }
    }
  },
  {
    path: '/orders/location-and-lot-item-adjustment/edit/:id',
    name: 'Abrir/Editar Ajuste - Inventário',
    component:  LocationAndLotAdjustment,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-adjustment/all',
        name: 'Ajuste de Itens'
      }
    }
  },
  {
    path: '/orders/item-adjustment/auto-fill-revision/:id',
    name: 'Revisar Sugestão do Ajuste',
    component:  AutoFillRevision,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-adjustment/all',
        name: 'Ajuste de Itens'
      }
    }
  },
]
