const Transformations = () =>import('@/views/orders/transformation/transformation-all')
const CreateTransformation = () =>import('@/views/orders/transformation/create')
const EditTransformation = () =>import('@/views/orders/transformation/edit')

export default [
  {
    path: '/orders/transformations/all',
    name: 'Transformação de Itens',
    component:  Transformations,
  },
  {
    path: '/orders/transformations/new',
    name: 'Nova Transformação',
    component:  CreateTransformation,
    meta: {
      breadcrumbParent: {
        path: '/orders/transformations/all',
        name: 'Transformação de Itens'
      }
    }
  },
  {
    path: '/orders/transformations/edit/:id',
    name: 'Editar Transformação',
    component: EditTransformation,
    meta: {
      breadcrumbParent: {
        path: '/orders/transformations/all',
        name: 'Transformação de Itens'
      }
    }
  }
]
