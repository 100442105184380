<template>
  <span class="badge" :class="adjustmentProcessStatusBadgeClassObject">
    {{adjustmentProcessStatusLabel}}
  </span>
</template>

<script>
  export default {
    name: 'stock-adjustment-process-status-badge',

    props: {
      stockAdjustment: {
        type: Object,
        require
      }
    },

    computed: {
      adjustmentProcessStatusBadgeClassObject() {
        return {
          'badge-danger': this.stockAdjustment.processStatus === 'E',
          'badge-warning': ['GP', 'GM', 'VD', 'VI'].includes(this.stockAdjustment.processStatus),
          'badge-success': ['PF', 'MF', 'VF', 'IF'].includes(this.stockAdjustment.processStatus)
        }
      },

      adjustmentProcessStatusLabel() {
        switch (this.stockAdjustment.processStatus) {
          case 'GP': return 'Gerando pedidos';
          case 'PF': return 'Geração de pedidos finalizada';
          case 'GM': return 'Gerando movimentos';
          case 'MF': return 'Geração de movimentos finalizada';
          case 'VD': return 'Verificando divergências de estoque';
          case 'VF': return 'Verificação finalizada';
          case 'VI': return 'Verificando inconsistências';
          case 'IF': return 'Verificação finalizada';
          case 'E': return 'Erro';
        }

        return this.stockAdjustment.processStatus;
      }
    }
  }
</script>
