import { HTTP } from '../http-commons';
import shared from '@/shared/shared';
import Router from '@/router/index';
import { version } from "../../../package.json";
import Vue from 'vue';

const authApiUrl = process.env.VUE_APP_AUTH_API_URL;
const authFrontUrl = process.env.VUE_APP_AUTH_FRONT_URL;

export default class AuthService {

  isDeprecatedVersion() {
    return new Promise((resolve, reject) => {
      let context = { isDeprecated: false };
      context.currentVersion = version;
      console.log("Versão: " + context.currentVersion);
      context.errorMessage = "Houve um erro ao verificar a versão";

      if (Vue.prototype.$isDeprecated !== undefined && Vue.prototype.$mostRecentVersion !== undefined) {
        context.isDeprecated = Vue.prototype.$isDeprecated;
        context.mostRecentVersion = Vue.prototype.$mostRecentVersion;
        resolve(context);
      }

      HTTP
      .get(`${process.env.VUE_APP_API_URL}test/front-version`)
      .then((data) => {
        if (data) {
          context.mostRecentVersion = data.data;
          Vue.prototype.$mostRecentVersion = data.data;
          console.log("Versão atual: " + context.mostRecentVersion);

          if (context.mostRecentVersion && context.mostRecentVersion.length) {
            if (context.currentVersion != context.mostRecentVersion) {
              let splitVersion = version.split(".");
              let splitResponse = context.mostRecentVersion.split(".");
              for (let i = 0; i < splitVersion.length; i++) {
                if (Number(splitVersion[i]) !== Number(splitResponse[i])) {
                  context.isDeprecated = true;
                  Vue.prototype.$isDeprecated = true;
                  break;
                }
              }

              if (context.isDeprecated) {
                resolve(context);
                return;
              }
            }

            Vue.prototype.$isDeprecated = false;
            console.log("A aplicação está atualizada");
            resolve(context);
            return;
          }
        }

        reject(context);
      })
      .catch((error) => {
        context.error = error;
        reject(context);
      });
    });
  }

  sendRecoverPasswordEmail(email) {
    let params = {};
    params.email = email;
    params.origin = window.location.href;
    return HTTP.post(authApiUrl + '/password-recovery-email', {}, { params: params });
  }

  changePassword(token, newPassword) {
    return HTTP.put(authApiUrl + `/change-password/${token}/${newPassword}`, {});
  }

  logout() {
    let localStorageKeysToKeep = ['config', shared.SORT_BY_VALUES_LOCAL_STORAGE_KEY];
    let valuesToKeep = {};
    localStorageKeysToKeep.forEach(key => {
      valuesToKeep[key] = localStorage.getItem(key);
    });

    localStorage.clear();

    Object.keys(valuesToKeep).forEach(key => {
      let value = valuesToKeep[key];

      if (value) {
        localStorage.setItem(key, value);
      }
    });

    shared.setLocalStorageObj('auth/user', {});
    //shared.setLocalStorageString('auth/token', null);
    //Router.push('/login');
    Vue.$cookies.remove('TeoremaAuthV2Token');
    this.redirectToLogin();
  }

  async setUserStorage() {
    //Para testes
    /*let companyGroup = this.getCompanyGroup();
    if (companyGroup && companyGroup.pivot && companyGroup.pivot.user_id) {
      await shared.fetchAuthUser(companyGroup.pivot.user_id, this.getToken());
    }*/

    //Produção
    let authApiUrl = this.getAuthApiUrl();

    if (authApiUrl) {
      await HTTP.get(`${authApiUrl}/api/v1/auth/me`).then(async data => {
        if (data && data.data) {
          let bwUser = data.data;

          localStorage.setItem('TeoremaAuthV2User', btoa(JSON.stringify(bwUser)));
          let companyGroup = this.getCompanyGroup();

          if (companyGroup && companyGroup.pivot && companyGroup.pivot.user_id) {
            await shared.fetchAuthUser(companyGroup.pivot.user_id, this.getToken());
          }
        }
      }).catch(error => {
        console.log(error)
      });
    }
  }

  getUser() {
    //Para testes
    /*try {
      return JSON.parse('{"id":"24335bbc-4deb-11ec-b4bd-0242ac170003","name":"testesdev","document":null,"birthday":null,"email":"dev@teorema.inf.br","email_verified_at":null,"password":"$2y$10$c5hB7rKzXU5kC0dD7BjLaeVKun9e7trgxNywFeINeRT00.xOb/xgu","login":"testesdev","cell_phone":null,"active":1,"super_admin":0,"created_at":"2021-11-25T12:28:06.000000Z","updated_at":"2021-11-25T12:28:06.000000Z","deleted_at":null,"change_password":false,"company_groups":[{"id":"5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac","ref":"0001_0000001","alias":"agentes","name":"agentes","pivot":{"bw_user_id":"24335bbc-4deb-11ec-b4bd-0242ac170003","company_group_id":"5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac","user_id":"23969dd4-fccf-46e1-ae78-52a95e01bd22","supplier_id":null,"customer_id":null,"status":null,"origin":null}}],"bw_user_company_groups":[{"bw_user_id":"24335bbc-4deb-11ec-b4bd-0242ac170003","user_id":"23969dd4-fccf-46e1-ae78-52a95e01bd22","company_group_id":"5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac","supplier_id":null,"customer_id":null,"status":null,"origin":null}],"bw_user_profiles":[{"id":"b9ff61e8-438d-441c-814b-d56acc0b54b7","active":null,"created_at":"2019-04-09T19:31:10.000000Z","deleted_at":null,"go_to_mobile":1,"ref":null,"updated_at":"2019-04-09T19:31:10.000000Z","name":"Administrador","root":1,"company_group_id":"5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac","pivot":{"bw_user_id":"24335bbc-4deb-11ec-b4bd-0242ac170003","profile_id":"b9ff61e8-438d-441c-814b-d56acc0b54b7"},"modules":[]}]}');
    } catch (e) {
      console.error(e);
      return null;
    }*/

    //Produção
    let storage = localStorage.getItem('TeoremaAuthV2User')

    let user = undefined
    if (storage) {
      storage = atob(storage);

      try {
        let temp = JSON.parse(storage)

        if (typeof temp === 'object') {
          user = temp;
        }
      } catch (e) {
        console.error(e);
      }

      //Para testes
      /*user.bw_user_profiles[0].modules = [];
      user.company_groups[0].config = null;
      console.log(JSON.stringify(user));*/
    }
    return user;
  }

  getToken() {
    //Para testes
    //return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoiMjQzMzViYmMtNGRlYi0xMWVjLWI0YmQtMDI0MmFjMTcwMDAzIiwicm9sZXMiOiJ1c2VyIiwic3ViIjoiZGV2QHRlb3JlbWEuaW5mLmJyIiwiaWF0IjoxNjc5MDY1MzUyLCJleHAiOjE2Nzk0OTczNTJ9.Jg42HFT3N3yNbwkr-r19is_3f-AhyuyKvSk9QeUH8FaeeCFJjc_dMJJlFijcAnsGPbODnxoPngTwAVvJDVLJdA';

    let token = Vue.$cookies.get('TeoremaAuthV2Token')
    if (token) {
      token = atob(token)
    }
    return token
  }

  getCompanyGroup() {
    //Para testes
    //return JSON.parse('{"id":"5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac","ref":"0001_0000001","alias":"agentes","name":"agentes","config":"{\\"isPraxis\\":true,\\"syncFenix\\":true,\\"goToMobile\\":true,\\"praxisModules\\":[\\"NEW_ORDERS\\",\\"ORDERS\\",\\"LAUNCHS\\",\\"LAUNCH_IN\\",\\"BUDGETS\\",\\"NEW_BUDGET\\",\\"LOST_SALES\\",\\"LIST_ITEMS\\",\\"ITEMS\\",\\"INTEGRATIONS\\"],\\"blockZeroedItems\\":false,\\"openedInstallments\\":[],\\"totalizeValuesPraxis\\":true}","pivot":{"bw_user_id":"24335bbc-4deb-11ec-b4bd-0242ac170003","company_group_id":"5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac","user_id":"23969dd4-fccf-46e1-ae78-52a95e01bd22","supplier_id":null,"customer_id":null,"status":null,"origin":null,"user":{"id":"23969dd4-fccf-46e1-ae78-52a95e01bd22","active":1,"read_all_customers":1,"created_at":"2021-11-25T11:53:03.000000Z","deleted_at":null,"go_to_mobile":1,"ref":"220","updated_at":"2022-04-29T17:48:39.000000Z","confirmed":1,"email":"dev@teorema.inf.br","login":"testesdev","name":"testesdev","type":"U","company_group_id":"5e7c3ea1-bbf8-4254-8d7d-8b730d7548ac","person_id":null,"profile_id":"8fd1340f-5844-4d37-8e20-84e652e3e0a4","config":{"itemFilter":false}}}}');

    let companyGroup = localStorage.getItem('TeoremaAuthV2CompanyGroup')
    if (!companyGroup) {
      companyGroup = Vue.$cookies.get('TeoremaAuthV2CompanyGroup')
    }
    if (companyGroup) {
      return JSON.parse(atob(companyGroup))
    }
    return null
  }

  setCompanyGroup(companyGroup) {
    if (companyGroup) {
      localStorage.setItem('TeoremaAuthV2CompanyGroup', btoa(JSON.stringify(companyGroup)))
    }
  }

  getAuthApiUrl() {
    let url = Vue.$cookies.get('TeoremaAuthV2ApiUrl');

    if (url) {
      return decodeURIComponent(atob(url));
    } else {
      return null;
    }
  }

  async redirectToLogin() {
    let data = await this.isDeprecatedVersion();
    if (!data || !data.isDeprecated) {
      if (window.origin.startsWith('http://localhost') && !authFrontUrl.startsWith('http://localhost')) {
        let msg = 'Copie os cookies e localStorage do wms de produção';
        console.log(msg);
        document.body.innerHTML = '<h1>' + msg + '</h1>';
      } else {
        location.href = authFrontUrl;
      }
    }
  }

  getAxiosHeaders() {
    let companyGroup = this.getCompanyGroup();

    return {
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': `Bearer ${this.getToken()}`,
      'Content-Type': 'application/json',
      'UserId': companyGroup && companyGroup.pivot ? companyGroup.pivot.user_id : null,
      'CompanyGroupId': companyGroup ? companyGroup.id : null
    };
  }

  isScrollOnBottom() {
    const SCROLL_NEXT_SLICE_MARGIN = 100;
    return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight - SCROLL_NEXT_SLICE_MARGIN;
  }

}
