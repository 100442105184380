const Groupers = () => import('@/views/warehouse/grouper/index')
const Groups = () => import('@/views/warehouse/grouper/group/index')

export default [
  {
    path: '/warehouse/groupers/all',
    component: Groupers,
    name: 'Agrupadores',
  },
  {
    path: '/warehouse/groups/all',
    component: Groups,
    name: 'Grupos',
    meta: {
      breadcrumbParent: {
        path: '/warehouse/groupers/all',
        name: 'Agrupadores',
      }
    }
  }
]
