import { HTTP } from '../http-commons';
import shared from '@/shared/shared';

export default class Allocation {

  url = process.env.VUE_APP_API_URL + 'locations';

  async get (url) {
   return await HTTP.get(url)
  }

  async getAllByName (name) {
    const { searchByName } = shared.getLocalStorageObj('endpoints/allocation');
    const  { data } = await HTTP.get(`${searchByName.href.replace('{name}', name)}`)
    return data
  }

  async getAllAvailable(warehouse) {
    const { allAvailable } = shared.getLocalStorageObj('endpoints/allocation');
    const { data } = await HTTP.get(`${allAvailable.href.replace('{UUID}', warehouse)}`)
    return data
  }

  async save (location) { 
    if (location.id) {
      return await this.update(location)
    }  else {
      return await this.create(location)
    }
  }

  async create (location) {
    location.stockLocale = await shared.getLocalStorageObj('warehouse/warehouse');

    //const { create: { href: create } } = localStorage.getItem('endpoints/allocation');
    const { data } = await HTTP.post(this.url, location); 
    return data
  }

  async update (location) {
    location.stockLocale = await shared.getLocalStorageObj('warehouse/warehouse');

    const { data } = await HTTP.put(this.url, location);
    return data
  }

  async delete (id) {
    await HTTP.delete(this.url + '/' + id)
  }

  async _extracted (url) {
    const { data } = await HTTP.get(url)
    const { page:contentPage, _embedded, _links } = data
    const { number:currentPage, size, totalElements, totalPages } = contentPage

    return {
      page: {
        content: [
          {
            numberOfPage: currentPage,
            items: _embedded.allocationsResourceList,
          }],
        size,
        totalElements,
        totalPages,
        currentPage,
        _links,
      },
    }
  }
}
