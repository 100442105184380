export default {
  namespaced: true,

  getters: {
    notifications: state => state.notifications,
    current_page: state => state.current_page,
  },

  state: {
    current_page: {
      title: 'W.M.S',
      size: 6
    },
    notifications: [],
  },

  actions: {
    async changePage({ commit }, { title, size }) {
      commit ('SET_CURRENT_PAGE', { title, size })
    },

    addNotification({ commit }, error) {
      commit ('SET_NOTIFICATIONS', error)
    }
  },

  mutations: {
    'SET_NOTIFICATIONS'(state, notifications) {
      if (notifications instanceof Array) {
        state.notifications = notifications;
      } else {
        state.notifications = [notifications];
      }
    },

    'SET_CURRENT_PAGE'(state, page) {
      state.current_page = page
    },
  }
}
