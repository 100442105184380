const Orders = () =>import('@/views/orders/itemsIn/items-in-all')
const OrderStart = () => import('@/views/orders/itemsIn/items-in-start')

export default [
  {
    path: '/orders/item-in/all',
    name: 'Entrada de Itens',
    component:  Orders,
  },
  {
    path: '/orders/item-in/start/:id',
    name: 'Executando Alocação',
    component: OrderStart,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-in/all',
        name: 'Entrada de Itens'
      }
    }
  }
]
