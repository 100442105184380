import Vue from 'vue'
import Router from 'vue-router'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/dashboard');
const Config = () => import('@/views/config/config');
const Users = () => import('@/views/user/index');
const Profiles = () => import('@/views/profile/index');
const FenixRegisters = () => import('@/views/sync/index');
const Parameters = () => import('@/views/parameters');
const Reports = () => import('@/views/reports');
const ReportView = () => import('@/views/report-view');
const Etiquetas = () => import('@/views/etiquetas');
const RegrasDeAlocacao = () => import('@/views/allocation-rules');

// Routes
import warehouse from './routers/warehouse'
import allocations from './routers/allocations'
import items from './routers/items'
import auth from './routers/auth'
import orderItemIn from './routers/orders-items-in'
import orderItemOut from './routers/orders-items-out'
import orderItemReallocation from './routers/orders-items-reallocation'
import orderItemAdjustment from './routers/orders-items-adjustment'
import orderItemTransformation from './routers/orders-items-transformation'
import orderTrack from './routers/orders-track'
import groupers from './routers/location-groupers'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    ...auth,
    {
      path: '/report',
      name: 'Relatório Pdf',
      component: ReportView,
      children: []
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: DefaultContainer,
      meta: {
        breadcrumbChildren: [
          '/warehouse/all',
          '/warehouse/items',
          '/warehouse/groups',
          '/orders',
        ]
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'warehouse',
          redirect: '/warehouse/all',
          name: "Armazém",
          component: {
            render (component) { return component('router-view') },
          },
          children: [
            ...warehouse,
            ...allocations,
            ...groupers,
            ...items
          ]
        },
        {
          path: 'order',
          name: 'Movimentação',
          component: {
            render (component) { return component('router-view') },
          },
          children: [
            ...orderItemIn,
            ...orderItemOut,
            ...orderItemReallocation,
            ...orderItemAdjustment,
            ...orderItemTransformation,
            ...orderTrack
          ]
        },
        {
          path: 'config',
          name: 'Configurações',
          component: Config
        },
        {
          path: 'users',
          name: 'Usuários',
          component: Users
        },
        {
          path: 'profiles',
          name: 'Perfis',
          component: Profiles
        },
        {
          path: 'sync',
          name: 'Sincronização',
          component: FenixRegisters
        },
        {
          path: 'parameters',
          name: 'Parametros',
          component: Parameters
        },
        {
          path: 'reports',
          name: 'Relatórios',
          component: Reports
        },
        {
          path: 'etiquetas',
          name: 'Etiquetas',
          component: Etiquetas
        },
        {
          path: 'allocation-rules',
          name: 'Regras de Alocação',
          component: RegrasDeAlocacao
        }
      ],
    },

    // {
    //   path: '/allocations',
    //   redirect: '/allocations/all',
    //   name: 'Alocações',
    //   component: DefaultContainer,
    //   children: [ ...allocations ]
    // }
      //   {
      //     path: 'actions',
      //     redirect: '/allocations/all',
      //     name: 'Ações internas',
      //     component: {
      //       render (component) { return component('router-view') },
      //     },
      //     children: [ ...configurations ]
      //   },
      //   {
      //     path: 'order',
      //     redirect: '/order/prepare/all',
      //     name: 'Pedidos',
      //     component: {
      //       render (component) { return component('router-view') },
      //     },
      //     children: [ ...ordersPrepare, ...ordersItemsIn ]
      //   },
      //   // {
      //   //   path: 'Configurations',
      //   //   redirect: '/configurations/warehouse',
      //   //   name: 'Configurações',
      //   //   component: {
      //   //     render (component) { return component('router-view') },
      //   //   },
      //   //   children: [ ...companiesGroups ]
      //   // },
      // ]
  ],

})
