import { SnotifyType } from 'vue-snotify'
import { RequestError } from '@/tools/RequestErrorHandler'

class Notify {
  constructor(store) {
    this.store = store;
  }

  success(message, title = 'Sucesso', config = {}) {
    this._commitNotifications(message, title, config, 'success');
  }

  info(message, title = 'Atenção', config = {}) {
    this._commitNotifications(message, title, config, 'info');
  }

  warn(message, title = 'Alerta', config = {}) {
    this._commitNotifications(message, title, config, 'warning');
  }

  textError(message, title = 'Erro', config = {}) {
    this._commitNotifications(message, title, config, 'error');
  }

  error(error, config = {}) {
    console.error(error);
    let errorMessage = 'Erro inesperado';

    if (typeof error === 'string') {
      errorMessage = error;
    } else if (typeof error === 'object') {
      if (error.message && error.message.length) {
        errorMessage = error.message;
      } else if (error.errors && error.errors.length) {
        errorMessage = error.errors;
      } else if (error.message && error.message.errors && error.message.errors.length) {
        errorMessage = error.message.errors;
      }
    }

    this._commitNotifications(errorMessage, 'Ocorreu um erro', config, 'error')
  }

  _commitNotifications(messages, title, config, type = 'simple') {
    if (!(messages instanceof Array)) {
      messages = [messages];
    }

    const notifications = messages.map((msg) => {
      return this._createToast(msg, title, config, type);
    });
    this.store.commit('app/SET_NOTIFICATIONS', notifications);
  }

  _handleRequestError(errorBag, title, config) {
    if (errorBag.hasFormErrors()) {
      this.error(errorBag.getFormErrorMessages(), title, config);
    } else {
      this.error(errorBag.message, title, config);
    }
  }

  _createToast(body, title, config = {}, type = 'simple') {
    if (!config.timeout)  {
      if (body && body.length) {
        config.timeout = Math.max(body.length * 100, 2000);
      } else {
        config.timeout = 3500;
      }
    }

    return { title, body, type, config };
  }
}

export default {
  install: (Vue, options = {}) => {
    if (!options.store) {
      throw '[NotifyPlugin] Store is required.';
    }
    Vue.prototype.$notify = new Notify(options.store);
  }
};
