import AuthService from './auth/AuthService'
import Allocation from '@/service/allocations/Allocation'
import Orders from '@/service/order/Orders'
import Reallocation from '@/service/reallocation/Reallocation'
import Company from '@/service/configurations/Company'
import ItemService from '@/service/allocations/ItemService'
import HttpClient from '@/service/http/HttpClient'
import HistoryItem from '@/service/history/HistoryItem'

const auth = new AuthService()
const allocation = new Allocation()
const order = new Orders()
const reallocation = new Reallocation()
const company = new Company()
const item = new ItemService()
const httpClient = new HttpClient()
const historyItem = new HistoryItem()

export  {
  auth,
  allocation,
  order,
  reallocation,
  company,
  item,
  httpClient,
  historyItem,
}
