import { HTTP } from '../http-commons'

export default class HttpClient {

  async get(url, config = {}) {
    return await HTTP.get(url, config);
  }

  async post(url, body, config = {}) {
    return await HTTP.post(url, body, config);
  }

  async put(url, body, config = {}) {
    return await HTTP.put(url, body, config);
  }

  async patch(url, body, config = {}) {
    return await HTTP.patch(url, body, config);
  }

  async delete(url, id, config = {}) {
    return await HTTP.delete(url + '/' + id, config);
  }

}
