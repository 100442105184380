const Item = () => import('@/views/warehouse/item')
const ItemDetails = () => import('@/views/warehouse/item/show')

export default [
  {
    path: '/warehouse/items/all',
    component: Item,
    name: 'Itens',
  },
  {
    path: '/warehouse/items/details/:id',
    name: 'Detalhes do Item',
    component:  ItemDetails,
    meta: {
      breadcrumbParent: {
        path: '/warehouse/items/all',
        name: 'Itens'
      }
    }
  }
]
