const Orders = () =>import('@/views/orders/prepare/prepare-all')
const OrderStart = () => import('@/views/orders/prepare/prepare-start')

export default [
  {
    path: '/orders/item-out/all',
    name: 'Saída de Itens',
    component:  Orders,
  },
  {
    path: '/orders/item-out/start/:id',
    name: 'Executando Separação',
    component: OrderStart,
    meta: {
      breadcrumbParent: {
        path: '/orders/item-out/all',
        name: 'Saída de Itens'
      }
    }
  }
]
