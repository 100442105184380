<template>
  <div>
    <b-modal
      size="lg"
      centered
      lazy
      title="Aplicação desatualizada"
      header-bg-variant="warning"
      footer-border-variant="warning"
      v-model="isDeprecated"
      :hide-footer="true"
    >
      <b-container>
        <div class="text-center" id="outdated-msg">
          <img src="@/assets/img/outdated-icon.png" class="m-n2" />

          <div class="row justify-content-center mx-0">
            <div
              class="col-lg-auto col-12 mx-2 p-3 bg-danger"
              id="outdated-msg-box"
            >
              <h2>A aplicação está desatualizada</h2>
              <h2>Versão que está neste dispositivo: {{ currentVersion }}</h2>
              <h2>Versão disponível: {{ mostRecentVersion }}</h2>
              <h4>Limpe o cache do navegador para atualizar a aplicação</h4>

              <div class="row mt-3 justify-content-center">
                <div class="col-sm-auto col-12">
                  <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click="forceUpdate"
                  >
                    Limpar cache automaticamente
                  </button>
                </div>
                <div class="col-sm-auto col-12 mt-sm-0 mt-3">
                  <button
                    type="button"
                    class="btn btn-lg btn-secondary mobile-btn-100"
                    @click="ignore"
                  >
                    Ignorar
                  </button>
                </div>
              </div>

              <h5 class="mt-4">
                <b>OBS:</b> Em alguns navegadores a função
                <b>Limpar cache automaticamente</b> pode não funcionar. Nesse
                caso será necessário limpar o cache manualmente:
              </h5>
              <button
                type="button"
                class="btn btn-secondary"
                @click="openCleanCacheInstructionsModal"
              >
                Como limpar o cache manualmente
              </button>
            </div>

            <b-modal
              size="md"
              centered
              lazy
              title="Como limpar o cache manualmente"
              header-bg-variant="info"
              footer-border-variant="info"
              v-model="cleanCacheInstructionsModalShow"
              :hide-footer="true"
            >
              <b-container class="pl-0 text-justify">
                <ul class="pl-2">
                  <li>
                    <h5 class="mb-3">
                      Se estiver acessando o WMS através de um computador basta
                      apertar Ctrl + F5.
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Se estiver acessando através do Chrome Android siga as
                      instruções:
                    </h5>
                    <ol>
                      <li>
                        No canto superior direito, toque em Mais
                        <img
                          src="//lh3.googleusercontent.com/oLoRPrHJd7m46sWijX6zBWnEnfslP62AxJSwt5Nj0bNbpaYHz2pyscExleiofsH2kQ=w18-h18"
                          width="18"
                          height="18"
                          alt="Mais"
                          title="Mais"
                        />.
                      </li>
                      <li>
                        Toque em <strong>Histórico</strong>
                        <img
                          src="//lh3.googleusercontent.com/SaY5lqCwN7kppnS546l9ys-E2sZftTTIHjBrdV-WsGPIhGjaxcEXjfgdIfW_UNG7Sw0=w13-h18"
                          width="13"
                          height="18"
                          alt="e"
                          title="e"
                        />
                        <strong>Limpar dados de navegação</strong>.
                      </li>
                      <li>
                        Na parte superior da tela, escolha um intervalo de
                        tempo. Para excluir tudo, selecione
                        <strong>Todo o período</strong>.
                      </li>
                      <li data-outlined="false" class="">
                        Marque as caixas ao lado de "Cookies e dados do site" e
                        "Imagens e arquivos armazenados em cache".
                      </li>
                      <li data-outlined="false" class="">
                        Toque em <strong>Limpar dados</strong>.
                      </li>
                    </ol>

                    <p class="mt-2 pl-2">
                      <small>
                        <b>Fonte:</b><br />
                        <a
                          target="_blank"
                          href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&hl=pt-BR"
                        >
                          https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&hl=pt-BR
                        </a>
                      </small>
                    </p>
                  </li>
                </ul>
              </b-container>
            </b-modal>
          </div>
        </div>
      </b-container>
    </b-modal>

    <div v-if="loading">
      <animation />
    </div>
    <router-view v-else></router-view>

    <b-card id="stock-adjustments" v-if="stockAdjustments.length">
      <b-card-title>
        <span class="mr-3">Ajustes de estoque ativos</span>
        <span>
          <b-button size="sm" variant="primary" v-on:click="closeAdjustments()"
            >X</b-button
          >
        </span>
      </b-card-title>
      <b-card-text>
        <b-list-group>
          <b-list-group-item
            v-for="item in stockAdjustments"
            :key="item.number"
          >
            <div>
              <a href="javascript:void(0)" @click.prevent="editItem(item)">
                {{ item.number }}
              </a>
            </div>
            <div>
              <stock-adjustment-process-status-badge
                :stockAdjustment="item"
              ></stock-adjustment-process-status-badge>
            </div>
            <div>
              <b-progress
                :max="item.stockAdjustmentItemsLength"
                style="min-width: 200px"
              >
                <b-progress-bar :value="item.numberOfItemsAdded">
                  {{ item.numberOfItemsAdded }} /
                  {{ item.stockAdjustmentItemsLength }}
                </b-progress-bar>
              </b-progress>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { auth } from "@/service";
import firebase from "./firebase";
import StockAdjustmentProcessStatusBadge from "@/components/stock-adjustment-process-status-badge";
import shared from "@/shared/shared";
import Animation from "@/components/loaders/animation";

export default {
  name: 'app',
  components: {
    StockAdjustmentProcessStatusBadge,
    Animation
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = 'WMS - ' + to.name;
      }
    },
  },

  data () {
    return {
      currentVersion: null,
      mostRecentVersion: null,
      isDeprecated: false,
      cleanCacheInstructionsModalShow: false,
      firebaseAdjustmentsDb: firebase.ref("stock-adjustments"),
      stockAdjustments: [],
      loading: true
    };
  },

  async beforeCreate() {
    //Util para debugar no celular
    //showConsoleLogOnHtmlBody();

    this.loading = true;
    await auth.isDeprecatedVersion().then(data => {
      this.currentVersion = data.currentVersion;
      this.mostRecentVersion = data.mostRecentVersion;
      this.isDeprecated = data.isDeprecated;
    }).catch(error => {
      if (error.message) {
        this.$notify.error(error);
      } else if (error.error.message) {
        this.$notify.error(error.error);
      } else if (error.errorMessage) {
        this.$notify.textError(error.errorMessage);
      }
    });

    await auth.setUserStorage();
    let user = auth.getUser();

    if (!user) {
      auth.logout();
    }

    this.init();
    this.loading = false;
  },

  methods: {
    init() {
      this.authUser = shared.getLocalStorageObj("auth/user");

      if (this.authUser && this.authUser.companyGroup && this.authUser.companyGroup.alias) {
        this.firebaseAdjustmentsDb.child(this.authUser.companyGroup.alias).on("child_changed", this.updateStockAdjustments);
      }
    },

    forceUpdate() {
      window.location.reload(true);
    },

    ignore() {
      this.isDeprecated = false;
    },

    openCleanCacheInstructionsModal() {
      this.cleanCacheInstructionsModalShow = true;
    },
    updateStockAdjustments(item) {
      let itemKey = item.key;
      let itemValue = { id: itemKey, ...item.val() };
      if (itemValue) {
        let index = this.stockAdjustments.map((x) => x.id).indexOf(itemKey);
        if (index >= 0) {
          this.stockAdjustments.splice(index, 1, itemValue);
        } else {
          this.stockAdjustments.push(itemValue);
        }
        if (itemValue.processStatus == "MF") {
          let _stockAdjustments = this.stockAdjustments;
          setTimeout(function () {
            _stockAdjustments.splice(index, 1);
          }, 5000);
        }
      }
    },
    editItem(item) {
      let url = "";

      if (item.type == "ARM") {
        url = "/orders/item-adjustment/edit/";
      } else if (item.type == "EEL") {
        url = "/orders/location-and-lot-item-adjustment/edit/";
      }

      this.$router.push(url + item.id);
    },
    closeAdjustments() {
      this.stockAdjustments = [];
    }
  },
};

var showConsoleLogOnHtmlBody = () => {
    // Reference to an output container, use 'pre' styling for JSON output
    var output = document.createElement('pre');
    document.body.appendChild(output);

    // Reference to native method(s)
    var oldLog = console.log;

    console.log = function( ...items ) {

        // Call native method first
        oldLog.apply(this,items);

        // Use JSON to transform objects, all others display normally
        items.forEach( (item,i)=>{
            items[i] = (typeof item === 'object' ? JSON.stringify(item,null,4) : item);
        });
        output.innerHTML += items.join(' ') + '<br />';

    };

    // You could even allow Javascript input...
    function consoleInput( data ) {
        // Print it to console as typed
        console.log( data + '<br />' );
        try {
            console.log( eval( data ) );
        } catch (e) {
            console.log( e.stack );
        }
    }
  };

</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.min.css";
/* Import Font Awesome Icons Set */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome.scss";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
@import "~flag-icon-css/css/flag-icon.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";

// #outdated-msg {
//   width: 100vw;
//   height: 100vh;
//   background-color: white;
// }

#outdated-msg img {
  max-width: 50%;
  max-height: 50%;
}

#outdated-msg-box {
  border-radius: 50px;
  border: #000 solid 3px;
}

#stock-adjustments {
  position: fixed;
  bottom: 15px;
  right: 15px;
  margin-bottom: 0;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.4);
}

@media (max-width: 576px) {
  #outdated-msg * {
    font-size: 15px;
  }
}
</style>
