class Acl {
  init(router, permissions) {
    this.router = router;
    this.permissions = Array.isArray(permissions) ? permissions : [permissions];
  }

  check(checkPermission) {
    if (typeof checkPermission !== 'undefined') {
      if (checkPermission === '') {
        return true;
      }

      const permissions = (checkPermission.indexOf('|') !== -1) ? checkPermission.split('|') : [checkPermission];

      return permissions.find((permission) => {
        const needed = (permission.indexOf('&') !== -1) ? permission.split('&') : permission;

        if (Array.isArray(needed)) {
          return needed.every(need => this.permissions.indexOf(need) !== -1);
        }

        return (this.permissions.indexOf(needed) !== -1);
      }) !== undefined;
    }

    return false;
  }

  set router(router) {
    router.beforeEach((to, from, next) => {
      const fail = to.meta.fail || from.fullPath;

      if (typeof to.meta.permission === 'undefined') {
        return next();
      }

      if (!this.check(to.meta.permission)) {
        return next(fail);
      }

      return next();
    });
  }
}

const acl = new Acl();

Acl.install = function (Vue, { router, init }) {
  acl.init(router, init);

  Vue.prototype.$can = function (permission) {
    return acl.check(permission);
  };

  Vue.prototype.$access = function (newAccess = null) {
    if (newAccess != null) {
      if (Array.isArray(newAccess)) {
        acl.permissions = newAccess;
      } else if (newAccess.indexOf('&') !== -1) {
        acl.permissions = newAccess.split('&');
      } else {
        acl.permissions = [newAccess];
      }
    } else {
      return acl.permissions;
    }
  };
};

export default Acl;
