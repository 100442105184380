import { HTTP } from '../http-commons'
import shared from '@/shared/shared';

export default class HistoryItem {

  async getAllHistory () {
    const { all: { href: historyItem } } = shared.getLocalStorageObj('endpoints/historyItem');
    const { uuid } = shared.getLocalStorageObj('item/item');
    return await HTTP.get(historyItem.replace('{UUID}', uuid))
  }
}
