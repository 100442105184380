import { HTTP } from '../http-commons'

export default class Reallocation {

  async getAllBy (url) {
    return await HTTP.get(url)
  }

  async reallocationItem ({ _links }, quantity) {
    const { addItem } = _links
    const { data:reallocation } = await HTTP.patch(addItem.href, { prepare: quantity})

    return reallocation
  }

  async completeTask ({ _links }) {
    const { completeReallocation } = _links
    const { data:reallocation } = await HTTP.patch(completeReallocation.href)
    return reallocation
  }
}
