import Axios from 'axios'
import { auth } from '@/service'
import { RequestError } from '@/tools/RequestErrorHandler'

const getNewClientToBaseUrl = () => {
  return Axios.create({
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json'
    },
    timeout: 5 * 60 * 1000
  })
}

const handleDefaultErrors = (error) => {
  if (error.response === undefined) {
    if (error.message) {
      throw new RequestError(error.message);
    } else {
      throw new RequestError('Erro inesperado');
    }
  }

  if (error.response.status === 422) {
    throw new RequestError(error.response.data)
  }

  if (error.response.status === 401 || error.response.status === 403) {
    auth.logout()
    throw new RequestError(error.response.data)
  }

  if (error.response.data.errors && error.response.data.errors.length) {
    throw new RequestError(error.response.data.errors[0])
  }

  throw error
}

const defineDefaultInterceptors = (client) => {
  client.interceptors.response.use((error) => error, handleDefaultErrors)
  client.interceptors.request.use((config) => {
    let token = auth.getToken();

    if (token && token != 'UNAUTHORIZED') {
      config.headers.Authorization =  `Bearer ${token}`;
    }

    let companyGroup = auth.getCompanyGroup();

    if (companyGroup && companyGroup.id) {
      config.headers.CompanyGroupId = companyGroup.id;
    }

    if (companyGroup && companyGroup.pivot && companyGroup.pivot.user_id) {
      config.headers.UserId = companyGroup.pivot.user_id;
    }

    return config
  })
}

let HTTP = getNewClientToBaseUrl()

defineDefaultInterceptors(HTTP)

export {
  HTTP,
}
