import { HTTP } from '../http-commons'
import shared from '@/shared/shared';

export default class ItemService {

  async getAllByName (name) {
    const { searchByName: { href: searchByName } } =  await shared.getLocalStorageObj('endpoints/item');
    const  { data } = await HTTP.get(`${searchByName.replace('{NAME}', name)}`)
    return data
  }
}
