import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
import 'vue-snotify/styles/material.css'
import 'v-slim-dialog/dist/v-slim-dialog.css'

import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import Snotify from 'vue-snotify'
import SlimDialog from 'v-slim-dialog'

import Notify from '@/plugins/NotifyPlugin'
import Acl from '@/plugins/Acl'
import store from '@/store'
import vClickOutside from "v-click-outside";
import VueConfirmDialog from 'vue-confirm-dialog';
import VueCookies from 'vue-cookies';
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
import VSwitch from 'v-switch-case'

// todo
// cssVars()

Vue.use(BootstrapVue)
Vue.use(Snotify)
Vue.use(Notify, { store })
Vue.use(SlimDialog)
Vue.use(Acl, { router, init: 'any' });
Vue.use(vClickOutside);
Vue.use(VueConfirmDialog);
Vue.use(VueCookies);
Vue.use(VSwitch);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

// Sentry.init({
//   Vue,
//   dsn: "https://dd928ada13de44edb5325167bac1164f@o1323705.ingest.sentry.io/6621776",
//   integrations: [
//     // new BrowserTracing({
//     //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     //   tracingOrigins: ["localhost", "wms.teorema.inf.br", /^\//],
//     // }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.01,
// });

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: { App },
  store
});
